.process0 {
    background: #e5e2ff;
    text-align: center;
}

.process0 > .process0_0 {
    padding-top: 8rem;
}

.process0 > .process0_0 img {
    max-width: 80%;
}

.process0 > .process0_1 {
    padding-top: 2rem;
}

.process0 > .process0_1 img {
    max-width: 60%;
}

.process0 > .process0_2 {
    padding: 4rem 0;
}

.process0 > .process0_2 img {
    max-width: 30%;
}

.process1 {
    text-align: center;
}

.process1 > .process1_0 {
    padding-top: 4rem;
}

.process1 > .process1_0 img {
    width: 100%;
}

.process1 > .process1_1 {
    padding: 2rem 0;
    text-align: center;
}

.process1 > .process1_1 ul {
    padding: 0;
    margin: 0;
}

.process1 > .process1_1 ul li {
    list-style: none;
}

.process1 > .process1_1 button {
    font-family: 'yg-jalnan', sans-serif;
    background: #e9e9e9;
    width: 80%;
    margin: .4rem 0;
    border: 2px solid #ffffff;
    border-radius: 5rem;
    font-size: 1.5rem;
    padding: .5rem 0;
}

.process1 > .process1_1 button.selected {
    border: 2px solid #5230cc;
}

/* process 2 */

.process2 {
    text-align: center;
}

.process2 > .process2_0 {
    padding-top: 4rem;
}

.process2 > .process2_0 img {
    width: 100%;
}

.process2 > .process2_1 {
    padding: 2rem 0;
    text-align: center;
}

.process2 > .process2_1 ul {
    padding: 0;
    margin: 0;
}

.process2 > .process2_1 ul li {
    list-style: none;
}

.process2 > .process2_1 button {
    font-family: 'yg-jalnan', sans-serif;
    background: #e9e9e9;
    width: 80%;
    margin: .4rem 0;
    border: 2px solid #ffffff;
    border-radius: 5rem;
    font-size: 1.5rem;
    padding: .5rem 0;
}

.process2 > .process2_1 button.selected {
    border: 2px solid #5230cc;
}

/* process 3 */

.process3 {
    text-align: center;
}

.process3 > .process3_0 {
    padding-top: 4rem;
}

.process3 > .process3_0 img {
    width: 100%;
}

.process3 > .process3_1 {
    padding: 2rem 0;
    text-align: center;
}

.process3 > .process3_1 ul {
    padding: 0;
    margin: 0;
}

.process3 > .process3_1 ul li {
    list-style: none;
}

.process3 > .process3_1 select {
    font-family: 'yg-jalnan', sans-serif;
    background: #e9e9e9;
    width: 80%;
    margin: .4rem 0;
    border: 2px solid #ffffff;
    border-radius: 5rem;
    font-size: 1.5rem;
    padding: .5rem 0;
    text-align-last:center;
    text-align: center;
}

.process3 > .process3_1 select.selected {
    border: 2px solid #5230cc;
}

/* process 4 */

.process4 {
    text-align: center;
}

.process4 > .process4_0 {
    padding-top: 4rem;
}

.process4 > .process4_0 img {
    width: 100%;
}

.process4 > .process4_1 {
    padding: 2rem 0 1rem 0;
    text-align: center;
}

.process4 > .process4_1 img {
    width: 40%;
}

.process4 > .process4_1 img.fadeIn {
    animation: fadein 3s;
    -moz-animation: fadein 3s; /* Firefox */
    -webkit-animation: fadein 3s; /* Safari and Chrome */
    -o-animation: fadein 3s; /* Opera */
}

.process4 > .process4_1 img.fadeOut {
    animation: fadeout 3s;
    -moz-animation: fadeout 3s; /* Firefox */
    -webkit-animation: fadeout 3s; /* Safari and Chrome */
    -o-animation: fadeout 3s; /* Opera */
}

.process4 > .process4_2 {
    padding: 1rem 0;
    text-align: center;
}

.process4 > .process4_2 img {
    width: 60%;
}

/* process 5 */

.process5 {
    text-align: center;
}

.process5 > .process5_0 {
    padding-top: 4rem;
}

.process5 > .process5_0 img {
    width: 100%;
}

.process5 > .process5_1 {
    font-family: 'yg-jalnan', sans-serif;
    font-size: 1.5rem;
    line-height: 120%;
    white-space: pre-wrap;
}

.process5 > .process5_2 {
    padding-top: 2rem;
}

.process5 > .process5_2 img {
    width: 80%;
}

.process5 > .process5_3 {
    margin-bottom: 2rem;
}

.process5 > .process5_3 h2 span {
    font-weight: normal;
}

.process5 > .process5_3 ul {
    padding: 0;
    margin: 0;
}

.process5 > .process5_3 ul li {
    list-style: none;
}

.process5 > .process5_4 {
    background: #f5f5f5;
    padding: 1rem;
    margin: 0 auto;
    width: 70%;
    font-size: 1rem;
    white-space: pre-wrap;
}

.process5 > .process5_5 {
    margin: 2rem auto;
    width: 80%;
    text-align: left;
    padding: 1rem;
    box-sizing: border-box;
    white-space: pre-wrap;
}

.process5 > .process5_6 {
    font-size: 0;
    padding-bottom: .5rem;
}

.process5 > .process5_6 img {
    width: 45%;
}

.process5 > .process5_7 ul {
    padding: 0;
    margin: 0;
}

.process5 > .process5_7 ul li {
    list-style: none;
}

.process5 > .process5_7 button {
    font-family: 'yg-jalnan', sans-serif;
    background: #e9e9e9;
    width: 80%;
    margin: .4rem 0;
    border: 2px solid #ffffff;
    border-radius: 5rem;
    font-size: 1.3rem;
    padding: .5rem 0;
}

.process5 > .process5_8 {
    padding: 2rem;
}

.process5 > .process5_8 img {
    width: 80%;
}

.process5_share_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, .8);
}

.process5_share_popup_content {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: 50%;
    background: #ffffff;
    margin-left: -25%;
    margin-top: -25%;
    box-sizing: border-box;
    padding: 1rem;
    border: 2px solid #eeeeee;
}

.process5_share_popup .share_buttons {
    padding-bottom: 1rem;
}
.process5_share_popup .share_buttons .share_button {
    width:36px;
    height:36px;
    margin: 0 .3rem;
    background: transparent no-repeat;
    background-size: 432px 144px !important;
    overflow: hidden;
    text-indent: -999px;
    border: 0;
}
.process5_share_popup .share_buttons .share_button.kakao {
    background-position: calc(-36px * 2) -36px;
}
.process5_share_popup .share_buttons .share_button.facebook {
    background-position: calc(-36px * 6) -36px;
}
.process5_share_popup .share_buttons .share_button.twitter {
    background-position: calc(-36px * 7) -36px;
}
.process5_share_popup .share_link_button {
    border: 1px solid #ccc;
    width: 100%;
    background: #ffffff;
    padding: .5rem 0;
}


/* process 6 */

.process6 {
    text-align: center;
}

.process6 > .process6_0 {
    padding-top: 4rem;
}

.process6 > .process6_0 img {
    width: 100%;
}

.process6_group {
    padding-top: 1rem;
}

.process6_group .process6_group_title {
    font-family: 'yg-jalnan', sans-serif;
}

.process6_group .process6_group_title span {
    background: #282828;
    color: #ffffff;
    width: 70%;
    margin: 0 auto;
    display: inline-block;
    border-radius: 3rem;
    padding: .5rem 0;
}

.process6_group ul {
    padding: 1.5rem 0;
    margin: 0;
}

.process6_group ul li {
    list-style: none;
    padding: 0;
    margin: .5rem .4rem;
    display: inline-block;
    width: 25%;
}

.process6_group ul li.hide {
    display: none;
}

.process6_group ul li .profile {
    width: 100%;
    height: 5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.process6_group ul li .name {
    text-align: center;
    font-family: 'yg-jalnan', sans-serif;
    background: #5230cc;
    color: #ffffff;
    padding: .1rem 0;
    font-size: 1rem;
}

.process6 .more-btn {
    background: #e9e9e9;
    color: #282828;
    border-radius: 2rem;
    padding: .5rem 3rem;
    border: 1px solid #e9e9e9;
    font-family: 'yg-jalnan', sans-serif;
    margin: 0 0 2rem 0;
}