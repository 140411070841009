.admin-layout {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #ffffff;
}

.admin-layout:after {
    clear: both;
    content: "";
    display: table;
}

.lnb {
    background: #2c2c36;
    float: left;
    width: 250px;
    height: 100%;
    position: relative;
}

.lnb .logo {
    margin: 45px 25px
}

.lnb .menu {
    padding: 0;
    margin: 0;
}

.lnb .menu li {
    list-style: none;
    padding: 1rem 0 1rem 3rem;
    position: relative;
    cursor: pointer;
}

.lnb .menu li span {
    color: #ffffff;
}

.lnb .menu li.selected span:after {
    content: ">>";
    position: absolute;
    right: 20px;
    top: 15px;
    color: #ffffff;
}

.lnb .menu li.selected {
    background: #1f1f2a;
    font-weight: 600;
}

.lnb .logout {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #ffffff;
    background: transparent;
    border: 0;
    cursor: pointer;
}

.content {
    float: left;
    background: #ffffff;
    width: calc(100% - 250px);
    text-align: left;
}


.login {
    text-align: center;
    font-family: 'yg-jalnan', sans-serif;
    color: #ffffff;
    padding-top: 200px;
}
.login .login-form {
    width: 500px;
    margin: 0 auto;
    text-align: center;
}

.login .login-form input {
    border: 0;
    padding: 7px 16px;
    margin: 2px 0;
    font-size: 24px;
    width: 100%;
    box-sizing: border-box;
    line-height: 40px;
    color: #000;
    border-radius: 10px
}

.login .login-form input[type=password] {
    font-family: sans-serif;
}

.login .btn-area {
    margin-top: 10px;
}
.login .btn-area button {
    width: 500px;
    height: 50px;
    font-size: 22px;
    border: 0;
    border-radius: 10px;
    line-height: 16px;
    background: #5230cc;
    color: #ffffff;
    font-family: 'yg-jalnan', sans-serif;
}

.list-container {
    width: 100%;
    height: 100%;
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.list-wrap {
}

.list-wrap .logo {
    margin: 80px 0 60px 0;
}

.list-wrap .table-wrap .btn-area {
    text-align: right;
    margin-bottom: 15px;
    width: 100%;
}

.list-wrap .table-wrap .btn-area > button {
    background: #363640;
    color: #ffffff;
    border-radius: 20px;
    padding: 7px 20px;
    border: 1px solid #ffffff;
    font-family: sans-serif;
    font-size: 14px;
    margin-left: 6px;
}

.list-wrap .table-wrap .btn-area > button.logout {
    border: 1px solid #363640;
    background: #ffffff;
    color: #363640;
}

.list-wrap .table-wrap {
    width: 80%;
    min-width: 1200px;
    margin: 0 auto;
}

.list-wrap .table-wrap table {
    width: 100%;
}

.list-wrap .table-wrap table th {
    color: #ffffff;
    background: #363640;
    padding: 10px 0;
    font-family: sans-serif;
    font-size: 16px;
}

.list-wrap .table-wrap table td {
    background: #d5d5d5;
    text-align: center;
    padding: 0;
    font-family: sans-serif;
    font-size: 16px;
}

.list-wrap .table-wrap table td img.profile {
    width: 100%;
}

.list-wrap .table-wrap table td button {
    background: transparent;
    border: 0;
    text-decoration: underline;
    font-weight: 600;
    font-family: sans-serif;
    font-size: 16px;
    padding: 0;
    margin: 0 .2rem;
    cursor: pointer;
}

.list-wrap .table-wrap table td button.btn-delete {
    color: red;
}

.pagination {
    text-align: center;
    margin: 40px 0 70px 0;
}

.pagination button {
    background: transparent;
    font-family: sans-serif;
    border: 0;
}

.pagination .pages {
    display: inline-block;
}

.pagination .pages button {
    margin: 0 10px;
}

.pagination .pages button.selected {
    font-weight: 600;
}

.search-wrap {
    width: 550px;
    margin: 50px auto 50px auto;
    text-align: center;
}

.search-wrap .react-datepicker-wrapper {
    vertical-align: top;
}

.search-wrap .title {
    background: #363640;
    color: #ffffff;
    border-radius: 30px;
    width: 120px;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    box-sizing: border-box;
    height: 30px;
    padding: 0 10px;
    font-family: sans-serif;
}

.search-wrap .title select {
    text-align-last:center;
    color: #ffffff;
    background: transparent;
    border: 0;
    height: 30px;
    font-size: 16px;
    padding-left: 10px;
    outline: none;
}

.search-wrap .title select option {
    color: #000000;
}

.search-wrap .search-row {
    margin: 10px 0;
}

.search-wrap input {
    border-radius: 30px;
    border: 1px solid #ccc;
    line-height: 28px;
    outline: none;
    padding: 0 15px;
    box-sizing: border-box;
    margin: 0 10px;
    vertical-align: top;
    width: 190px;
    text-align: center;
    font-family: sans-serif;
    color: #bbbbbb;
}

.search-wrap .search-row:nth-child(2) input {
    width: 322px;
}

.search-wrap button.btn {
    font-size: 16px;
    padding: 0;
    line-height: 30px;
    width: 80px;
    box-sizing: border-box;
    height: 30px;
    border-radius: 30px;
    border: 0;
    background: #1f1f2a;
    color: #ffffff;
}

.search-wrap .react-datepicker__tab-loop {
    display: inline-block;
}

.all-btn {
    width: 300px;
    margin: 0 auto;
}

.all-btn button {
    width: 100%;
    font-size: 18px;
    padding: 0;
    line-height: 40px;
    margin-bottom: 50px;
}

.detail-pop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
}

.detail-inner-pop {
    width: 800px;
    height: 750px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    margin-left: -400px;
    margin-top: -325px;
    background: #363640;
    overflow-y: scroll;
    font-family: sans-serif;
    letter-spacing: -1px;
}

.detail-inner-pop .profile {
    background: #1f1f27;
    padding: 10px 50px;
}

.detail-inner-pop .profile li {
    width: 50%;
    display: inline-block;
    margin: 10px 0;
}

.detail-inner-pop .profile ul {
    margin: 0;
    padding: 0;
}

.detail-inner-pop .profile li.full {
    width: 100%;
}

.detail-inner-pop .profile span.title {
    color: #ffffff;
    font-weight: 600;
    display: inline-block;
    width: 150px;
}

.detail-inner-pop .profile span {
    color: #15abd0;
}

.detail-inner-pop .question-answer-list {
    padding: 30px 50px;
}

.detail-inner-pop .question-answer-list ul {
    padding: 0;
    margin: 0;
}

.detail-inner-pop .question-answer-list > ul > li {
    color: #ffffff;
    list-style: none;
}

.detail-inner-pop .question-answer-list ul li .answer {
    color: #15abd0;
    padding: 10px 0 20px;
}

.detail-inner-pop .question-answer-list ul li .answer ul {
    display: inline-block;
    padding: 0;
    margin: 0;
}

.detail-inner-pop .question-answer-list ul li .answer ul li {
    color: #7c7c7c;
    display: inline-block;
    margin: 0 3px;
}

.detail-inner-pop .question-answer-list ul li .answer ul li.on {
    color: #01b8ff;
    font-weight: 600;
}

.edit-form {
    width: 100%;
    box-sizing: border-box;
    padding: 0 2rem;
}

.edit-form table {
    width: 800px;
    padding: 1rem 2rem;
    border-collapse: collapse;
}

.edit-form table th {
    background: #1f1f2a;
    color: #ffffff;
    padding: 1rem 2rem;
    border-bottom: 1px solid #cccccc;
}

.edit-form table td {
    background: #f2f2f2;
    padding: 1rem 2rem;
    border-bottom: 1px solid #cccccc;
}

.edit-form table td img {
    display: block;
    max-width: 100%;
}

.edit-form table td textarea {
    width: 100%;
    height: 300px;
}

.edit-form .btn-area {
    padding: 2rem 0;
}

.edit-form .btn-area .btn {
    width: 200px;
    height: 40px;
}